.thumbnail{
    padding: 2%;
    aspect-ratio:1/1;
    color:black;
    outline:rgba(0, 0, 0, 0) 1px solid;
    display: grid;
    grid-template-rows: 9fr 7fr;

}

.articleLink {
    text-decoration: none;
    color: black;
}

.thumbnail  *{
    padding:0px;
}

.thumbnail:hover{
    /* transform: scale(1.05); */
    outline:1px black solid;
}

.thumbnail-image{
    outline:green 0px solid;
    display: block;
    width: 100%;
    aspect-ratio: 16/9;
    margin-bottom: 0.7em;
} 

.thumbnail-text{
    padding:0%;
    display: flex;
    flex-direction: column;
    justify-items: center;
}
.thumbnail-text > *{
    margin: 0;
    outline: 0px solid purple;
}

.thumbnail-date{
    
    color: rgb(86, 86, 86);
    padding:0%;
    margin-top:0.3em;
    display: flex;
    flex-direction: column;
    justify-items: center;
}