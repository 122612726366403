.ad-image{
    width:100%;

}

.visibleAdWrapper{
    display: flex;
    flex-direction: column;
    justify-content: center;
}
