



.quiz-container {
  max-width: 500px;
  min-width: 250px;
  background: #ffffff;
  border-color: red;
  border-radius: 4px;
  margin-top: 100px;
  padding: 30px 60px;
}

.quiz-container .active-question-no {
  font-size: 32px;
  font-weight: 500;
  color: #800080;
}

.quiz-container .total-question {
  font-size: 16px;
  font-weight: 500;
  color: #e0dee3;
}

.quiz-container h2 {
  font-size: 20px;
  font-weight: 500;
  margin: 0;
}

.quiz-container ul {
  margin-top: 20px;
  margin-left: -40px;
}

.quiz-container ul li {
  text-decoration: none;
  list-style: none;
  color: #2d264b;
  font-size: 16px;
  background: #ffffff;
  border: 1px solid #eaeaea;
  border-radius: 16px;
  padding: 11px;
  margin-top: 15px;
  cursor: pointer;
}

.quiz-container ul .selected-answer {
  background: #ffd6ff;
  border: 1px solid #800080;
}

.quiz-container button {
  background: linear-gradient(90.04deg, #800080 0.03%, #ffc0cb 99.96%);
  border-radius: 9px;
  font-size: 18px;
  color: #ffffff;
  padding: 10px 42px;
  outline: none;
  border: none;
  cursor: pointer;
  margin-top: 15px;
}

.quiz-container button:disabled {
  background: #e7e8e9;
  color: #9fa3a9;
  cursor: not-allowed;
}

.flex-right {
  display: flex;
  justify-content: flex-end;
}

.result h3 {
  font-size: 24px;
  letter-spacing: 1.4px;
  text-align: center;
}

.result p {
  font-size: 16px;
  font-weight: 500;
}

.result p span {
  color: #800080;
  font-size: 22px;
}