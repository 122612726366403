.comment {
    display: flex;
    margin-bottom:1em;

}

.comments{
    border-top:2px black solid;
    margin-top: 3em;
    margin-bottom: 5em;
}

.commentAuthor {
    font-weight: bold;
}

.comment .content{
   margin-left:0.5em;
}

