.articles-container *{
  box-sizing: border-box; 
}

.articles-container{  

    width:85%;
    margin:auto;
    display: grid;
    transition: 0.22s ease-in;
    grid-template-columns: 1fr 1fr 1fr;
    margin-bottom: 15%;
}

.homepage-content{
    display: grid;
    grid-template-columns:1fr 6fr 1fr;
}

.left-ads{
    margin-left: 1em;
    margin-top: 0;
}

.right-ads{
    margin-right: 1em;
    margin-top: 0;
}

.disclaimer-container{
    max-width: 100vw;
}

@media  only screen and (min-width:551px) and (max-width:800px) {
    .articles-container{
        grid-template-columns: 1fr 1fr;
        width:80vw
    }
    .homepage-content{
        display: grid;
        grid-template-columns: 6fr 1fr;
    }
    .left-ads{
        display: none;
        margin-left: 1em;
        margin-top: 0;
    }
}

@media  only screen and (min-width:0px) and (max-width:550px) {
    .articles-container{
        grid-template-columns: 1fr;
        width:80vw
    }
    .homepage-content{
        display: grid;
        grid-template-columns: 6fr 1fr;
    }
    .left-ads{
        display: none;
        margin-left: 1em;
        margin-top: 0;
    }
}
